// Global layout styles

body {
  margin: 0;
}

a {
  transition: color $transition-default;
  color: $color-branding-link-text;
  text-decoration: none;

  &:hover {
    color: $color-branding-link-text--hover;
    text-decoration: underline;
  }
}

ul {
  font-size: $font-size-body;
  line-height: 1.75rem;

  li {
    font-weight: $font-weights-bold;
  }
}

