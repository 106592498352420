.c-hamburger {
  $size: 2.75rem;

  width: $size;
  height: $size;
  margin-left: -1.5625rem;
  padding: 1rem .625rem;
  position: relative;
  display: block;
  background: $color-branding-background;
  border-radius: 50%;

  &:before,
  &:after,
  span {
    content: '';
    display: block;
    width: 1.5rem;
    height: 2px;
    background-color: $color-branding-button;
    position: absolute;
    transition: all $transition-default;
    will-change: opacity, transform;
  }

  &:before {
    top: .875rem;
  }

  &:after {
    bottom: .875rem;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-open {
    &:before,
    &:after {
      opacity: 0;
      transform: scaleX(0.5);
    }

    span {

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
