$context: "frontend";
/* Load fonts & variables first, because it's a dependency of other CSS */
// Font
@import url("https://use.typekit.net/cch1zhd.css");

// Variables
@import 'variables/colors';
@import 'variables/grid';
@import 'variables/own';

// Dependencies
@import 'dependencies/bootstrap';

/*
 * Import Base files
 * - Styling for base html elements, like body or headings
 * - No class styling
 */
@import 'base/base';
@import 'base/mixins';
@import 'base/typography';

/*
 * Import Structure components:
 * - Prefixed with: [.s-]
 * - Used for (custom) container, grids, flex-layouts or other (layout) structures
 * - Use bootstrap first
 */
@import 'structure/footer';
@import 'structure/header';
@import 'structure/wrapper';

/*
 * Import blocks
 * - Prefixed with: [.b-]
 */
@import 'blocks/base-block';
@import 'blocks/header';
@import 'blocks/recent-blogs';
@import 'blocks/text-image';

/*
 * Import Components:
 * - Prefixed with: [.c-]
 * - Used for independent components button, main-menu, card, article
 */
@import 'components/buttons';
@import 'components/contact-panel';
@import 'components/hamburger';
@import 'components/menu';
@import 'components/menu-mobile';
@import 'components/side-menu';
@import 'components/sidepanel-image';
@import 'components/sidepanel-price';

/*
 * Templates
 * - Prefixed with: [.t-]
 */

@import 'templates/detail';
