.c-menu-mobile {
  position: fixed;
  top: $header-height-mobile;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: $color-white;
  overflow-y: scroll;
  transition: transform $transition-default;
  transform: translateX(-100%);

  &.is-open {
    transform: translateX(0);
  }
}

.c-menu-mobile__item {

}

.c-menu-mobile__item--sub-item {
  .c-menu-mobile__link {
    font-weight: $font-weights-regular;
  }
}

.c-menu-mobile__item--sub-sub-item {
  .c-menu-mobile__item-inner {
    min-height: 2rem;
    border: none;
  }

  .c-menu-mobile__link {
    font-weight: $font-weights-regular;
    font-size: 1rem;
  }
}

.c-menu-mobile__item-inner {
  border-top: 1px solid $color-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
}

.c-menu-mobile__item-inner--last {
  border-bottom: 1px solid $color-border;
}

.c-menu-mobile__link {
  display: block;
  padding: .5rem 0;
  font-size: 1.125rem;
  color: $color-text;
  text-decoration: none;
  font-weight: $font-weights-bold;
  line-height: 1.375rem;
  width: 100%;
  height: 100%;

  &:hover {
    text-decoration: none;
    color: $color-text;
  }
}

.c-menu-mobile__sub-menu-btn {
  padding: .5rem;

  &:hover {
    cursor: pointer;
  }
}

.c-menu-mobile__chevron-down {
  background-image: url(../img/chevron-square-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1.375rem;
  width: 1.375rem;
}

.c-menu-mobile__sub-menu-container {

}

.c-menu-mobile__sub-sub-menu-container {

}

.c-menu-mobile__bottom {
  display: none;
  font-size: .875rem;
  margin-top: 2.25rem;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.c-menu-mobile__bottom-item {
  display: flex;
  align-items: center;
  margin-right: 3.125rem;
  margin-bottom: .75rem;
  white-space: nowrap;
}

.c-menu-mobile__bottom-item-icon {
  width: .875rem;
  height: .875rem;
  margin-right: .5rem;

  svg {
    display: block;

    path {
      fill: $color-branding-link-text;
    }
  }
}

.c-menu-mobile__bottom-item-text {

}

.c-menu-mobile__bottom-item-link {
  margin-left: .375rem;
}
