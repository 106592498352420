.c-button {
  display: inline-block;
  transition: background-color $transition-default;
  background: $color-branding-button;
  padding: .5rem 1.75rem;
  border-radius: 1.75rem;
  color: $color-white;
  text-decoration: none;
  font-weight: $font-weights-bold;
  font-size: 1.125rem;
  margin-right: 1rem;
  margin-bottom: .5rem;
  border: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: $color-white;
    background: $color-branding-button--hover;
  }

  @include media-breakpoint-down(md) {
    padding: .5rem 1rem;
    font-size: 1rem;
  }
}

.c-button--thin {
  font-size: .875rem;
  padding: .25rem 2.5rem;
}

.c-button--arrow {
  position: relative;
  padding-right: 3rem;

  &:after {
    content: '';
    background-image: url(../img/arrow-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 1rem;
    height: 1.75rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-button--ghost {
  border: .125rem solid $color-white;
  background: transparent;

  &:hover {
    background: rgba(255, 255, 255, .3);
  }
}

.c-button--square {
  border-radius: $border-radius;
}
