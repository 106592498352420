.c-sidepanel-image {
  background: $color-white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
  margin-bottom: 2rem;
  transform: translate(2rem, -3.5rem);
  padding: 1rem;

  @include media-breakpoint-up(xl) {
    transform: translate(3rem, -3.5rem);
  }

  @include media-breakpoint-down(md) {
    transform: initial;
    margin-bottom: 1rem;
  }
}

.c-sidepanel-image__image-container {
  position: relative;
  overflow: hidden;
  height: 7.875rem;
  width: 100%;
}

.c-sidepanel-image__image {
  @include absolute-centered-image(100%, 100%, 100%, 100%);
}

.c-sidepanel-image__title {
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-weight: $font-weights-bold;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.c-sidepanel-image__button {
  width: 100%;
  text-align: center;
  border-radius: $border-radius;
}
