// Custom implementations, always prefix this with $color-[element/component] i.e $color-text-primary or $color-text-secondary

// Branding colors
$color-branding-link-text: var(--color-link-text);
$color-branding-button: var(--color-button);
$color-branding-background: var(--color-background);
$color-branding-circle-background: var(--color-circle-background);

// Branding hover states
$color-branding-background--hover: var(--color-background--hover);
$color-branding-button--hover: var(--color-button--hover);
$color-branding-link-text--hover: var(--color-link-text--hover);

// Default colors
$color-text: #212B36;
$color-text-light: #637381;
$color-border: #D8D8D8;
$color-border-light: #E2E4ED;
$color-white: #FFF;
$color-black: #000;
