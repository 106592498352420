$image-height: 25rem;
$image-height-mob: 18rem;

.t-detail {
  padding-top: $image-height;

  @include media-breakpoint-down(md) {
    padding-top: $image-height-mob;
  }
}

.t-detail--wave {
  .t-detail__wave-shape {
    display: block;
  }
}

.t-detail__wave-shape {
  display: none;

  width: 120vw;
  height: 40rem;
  position: absolute;
  top: 0;
  left: -34vw;
  z-index: -1;

  @include media-breakpoint-down(lg) {
    top: -18rem;
    left: -10vw;
    height: 30rem;
  }

  @include media-breakpoint-down(md) {
    width: 110vw;
    left: -10vw;
    right: 0;
    height: 14rem;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $color-branding-background;
    }
  }
}

.t-detail__image-container {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: $image-height;
  transform: translateX(-50%);

  @include media-breakpoint-down(md) {
    width: 100%;
    height: $image-height-mob;
  }
}

.t-detail__header-image {
  @include absolute-centered-image(100%, 100%, 100%, 100%);

  top: 0;
  transform: translateX(-50%);
}

.t-detail__breadcrumbs-outer-container {
  background: rgba($color-text, .6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.t-detail__breadcrumbs {
  padding: .5rem 0;
  color: $color-white;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.t-detail__breadcrumb {
  color: $color-white;
  font-size: 1.25rem;
  font-weight: $font-weights-bold;
  line-height: 2.5rem;

  &:hover {
    color: $color-white;
  }
}

.t-detail__breadcrumb--active {
  color: rgba($color-white, .85);

  &:hover {
    color: rgba($color-white, .85);
  }
}

.t-detail__content {
  padding-bottom: 6.5rem;
  margin-top: 2rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 3.5rem;
  }
}
