@mixin absolute-centered-image($image-width, $image-height, $image-width-mob, $image-height-mob) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: $image-width;
  min-height: $image-height;
  height: auto;
  width: auto;

  @include media-breakpoint-down(md) {
    min-width: $image-width-mob;
    min-height: $image-height-mob;
  }
}
