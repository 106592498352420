.c-side-menu {
  background: $color-white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transform: translate(2rem, -3.5rem);
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    transform: translate(4rem, -3.5rem);
  }

  @include media-breakpoint-down(md) {
    transform: translateY(-3rem);
  }
}

.c-side-menu__title {
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: $font-weights-bold;
  background: $color-branding-background;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.c-side-menu__item {
  padding: .5rem 1.25rem;
  font-size: 1rem;
  line-height: 2.5rem;
  font-weight: $font-weights-bold;
  border-top: 1px solid $color-border-light;
  display: block;
  transition: background-color $transition-default;

  &:last-child {
    border-bottom: 1px solid $color-border-light;
  }

  &:hover {
    color: $color-white;
    background: $color-branding-button--hover;
    text-decoration: none;
  }
}

.c-side-menu__item--hide-mob {
  display: block;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.c-side-menu__show-more {
  display: none;
}

.c-side-menu__show-more-text {
  display: none;
  font-size: 1rem;
  line-height: 2.5rem;
  font-weight: $font-weights-bold;
  transition: color $transition-default;
  color: $color-branding-link-text;
  border-top: 1px solid $color-border-light;
  padding: .5rem 1.25rem;

  &:after {
    content: 'Toon meer..';
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    &:after {
      content: 'Toon minder..';
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}
