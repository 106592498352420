//Add your own variables here!
/*
 * Add your own variables here
 *
 * Think about breakpoints (that effect multiple components, default settings for transients, border-radius, box-shadows etc.
 */

// Animations and transitions
$transition-default-speed: 250ms;
$transition-default-ease: ease-in-out;
$transition-default: $transition-default-speed $transition-default-ease;

// Menu variables
$menu-breakpoint: md;
$header-height: 6.5rem;
$header-height-mobile: 4.875rem;

// Base font
$font-family-base: 'pt-sans-pro', 'Arial', sans-serif;

// Font sizes
$font-size-body: 1.125rem;
$font-size-body-mob: 1rem;

// Font weights
$font-weights-regular: 400;
$font-weights-bold: 700;

// Z-index
$z-index-header: 30;

$border-radius: .375rem;
$box-shadow: 0 .125rem .25rem 0 rgba(0, 0, 0, .17);
$block-margin: 5rem;
$block-margin-mob: 2rem;
