.c-menu {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: -1.5rem;
}

.c-menu__item {
  position: relative;

  &:hover {
    .c-menu__link {
      text-decoration: none;
      color: $color-text;

      &:after {
        transform: scaleX(1);
      }
    }

    .c-menu__sub-menu-container {
      pointer-events: initial;
      opacity: 1;
    }

    .c-menu__sub-sub-menu--active {
      opacity: 1;
      pointer-events: initial;
      height: initial;
      padding: 1.625rem 1rem;
    }

    &.c-menu__item--has-children {
      &:after {
        opacity: .5;
      }
    }
  }
}

.c-menu__item--has-children {
  &:after {
    content: '';
    transition: opacity $transition-default 250ms, transform $transition-default;
    opacity: 0;
    background-color: $color-black;
    position: fixed;
    left: 0;
    right: 0;
    top: $header-height;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
  }
}

.c-menu__link {
  position: relative;
  padding-bottom: .5rem;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: $color-text-light;
  font-weight: $font-weights-bold;
  font-size: 1rem;

  &:after {
    transition: transform $transition-default;
    content: '';
    height: .125rem;
    width: 100%;
    background: $color-branding-link-text;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left;
    transform: scaleX(0);
  }
}

.c-menu__sub-menu-container {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-top: 1rem;
  transform: translateY(100%);
  opacity: 0;
  width: 71rem;
  z-index: 1;
  transition: opacity $transition-default;
  transition-delay: 250ms;
  pointer-events: none;

  @include media-breakpoint-down(lg) {
    width: 60rem;
  }
}

.c-menu__sub-menu-col {
  background: $color-white;
  padding-top: 1.5rem;
  height: fit-content;
}

.c-menu__sub-item {
  position: relative;
  display: block;
  text-decoration: none;
  z-index: 0;
  padding: .5rem 1rem;
  font-weight: $font-weights-bold;
  color: $color-text-light;
  font-size: 1rem;
  overflow: hidden;

  &:before {
    content: '';
    background: $color-branding-background;
    position: absolute;
    top: 0;
    left: -.9375rem;
    width: calc(100% + 2rem);
    height: 100%;
    transition: opacity $transition-default;
    opacity: 0;
    z-index: -1;
  }
}

.c-menu__sub-item:hover, .c-menu__sub-item--active {
  text-decoration: none;
  color: $color-text;

  &:before {
    opacity: 1;
  }
}

.c-menu__sub-item--has-sub {
  &:after {
    content: '';
    background-image: url(../img/chevron-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: .75rem;
    width: .75rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-menu__sub-sub-menu-col {

}

.c-menu__sub-sub-menu {
  opacity: 0;
  pointer-events: none;
  height: 0;
  background: $color-branding-background;
}

.c-menu__sub-sub-menu-inner-col {
  margin-right: 4rem;
}

.c-menu__sub-sub-item {
  display: block;
  text-decoration: none;
  font-weight: $font-weights-bold;
  margin-bottom: 1.5rem;
  font-size: .9375rem;
  color: $color-text-light;

  &:hover {
    text-decoration: none;
    color: $color-text;
  }
}
