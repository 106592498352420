body {
  &.mob-menu-is-open {
    overflow-y: hidden;
  }
}

.s-header {
  background: $color-white;
  z-index: $z-index-header;
  position: fixed;
  left: 0;
  right: 0;
  padding: 1rem 0;
  transition: box-shadow $transition-default;

  &.is-scrolled {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

    .s-header__main {
      margin-top: 1.5rem;
      height: 2.5rem;
    }

    .c-menu__item--has-children {
      &:after {
        transform: translateY(-.5rem);
        height: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: .25rem 0;
  }
}

.s-header--gradient {
  background: linear-gradient(60deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(0,0,0,0.6) 39%, rgba(0,0,0,0.6) 100%);

  .c-menu__item {
    .c-menu__link {
      color: $color-white;
    }

    &:hover {
      .c-menu__link {
        color: $color-white;
      }
    }
  }
}

.s-header__container {
  display: flex;
  position: relative;

  @include media-breakpoint-down(md) {
    justify-content: space-between;
    height: $header-height-mobile;
    padding-bottom: 1rem;
  }
}

.s-header__logo {
  padding-right: 8.75rem;
  margin: auto;

  img {
    height: 3.875rem;
    width: auto;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
    margin: auto 2rem 0 0;

    img {
      height: 2.75rem;
    }
  }
}

.s-header__menus {
  width: 100%;

  @include media-breakpoint-down(md) {
    width: fit-content;
    margin-top: auto;
  }
}

.s-header__main {
  transition: all $transition-default;
  margin-top: 1rem;
  height: 3.5rem;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.s-header__main-mobile {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
    align-items: center;
  }
}

.s-header__main-mobile-phone {
  margin-right: 3rem;
  width: 1.375rem;
  height: 1.375rem;

  svg {
    path {
      fill: $color-branding-button;
    }
  }
}
