.s-footer {

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.s-footer__socials {
  display: flex;
  margin-top: 2rem;

  @include media-breakpoint-down(md) {
    margin-top: 1.25rem;
    margin-bottom: 2rem;
    justify-content: center;
  }
}

.s-footer__social-link {
  background: $color-branding-background;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  margin-right: 1rem;
  transition: background-color $transition-default;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 1.875rem;
    height: 1.875rem;
    margin: auto;

    path {
      transition: fill $transition-default;
      fill: $color-branding-button;
    }
  }

  &:hover {
    background: $color-branding-background--hover;

    svg {

      path {
        fill: $color-branding-button--hover;
      }
    }
  }

  @include media-breakpoint-down(md) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.s-footer__menu-row {
  margin-top: 1.375rem;

  @include media-breakpoint-down(md) {
    margin-top: 2.5rem;
  }
}

.s-footer__menu {
  margin-bottom: 2rem;
}

.s-footer__menu-title {
  font-weight: $font-weights-bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

.s-footer__image {
  border-radius: $border-radius;
  max-width: 100%;
}

.s-footer__menu-item {
  display: block;
  font-size: 1rem;
  color: $color-text-light;
  line-height: 1.75rem;
  text-decoration: none;
}

.s-footer__menu-item--small {
  font-size: .875rem;
  margin-right: 1.875rem;

  &:last-child {
    margin-right: 0;
  }

  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.s-footer__address {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.s-footer__disclaimer {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-top: 2.5rem;
  }
}

.s-footer__disclaimer-menu {
  display: flex;

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
    flex-direction: column;
  }
}

.s-footer__logos-container {

}

.s-footer__logo {
  height: 2.875rem;
  width: auto;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  @include media-breakpoint-down(md) {
    margin-right: 1rem;
  }
}
