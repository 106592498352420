.c-sidepanel-price {
  padding: 1rem 1.25rem;
  background: $color-white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 2rem;
  transform: translate(2rem, -3.5rem);

  @include media-breakpoint-up(xl) {
    transform: translate(3rem, -3.5rem);
  }

  @include media-breakpoint-down(md) {
    transform: initial;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
  }
}

.c-sidepanel-price__title {
  font-size: 1.25rem;
  line-height: 2.5rem;
  font-weight: $font-weights-bold;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
  }
}

.c-sidepanel-price__price {
  font-weight: $font-weights-bold;
  font-size: 1.125rem;
  line-height: 2.5rem;
  border-bottom: 1px solid $color-border-light;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.c-sidepanel-price__currency {
  font-size: 1.125rem;
  line-height: 2.5rem;
  color: $color-text-light;
  font-weight: $font-weights-regular;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.c-sidepanel-price__text {
  font-size: .9375rem;
  line-height: 1rem;
  color: $color-text-light;
  margin-top: 1rem;
}
