$image-height: 38rem;
$image-height-mob: 25.875rem;
$card-height: 16.5rem;
$card-height-mob: 9.5rem;

.b-header {
  margin-top: 0;
  position: relative;
  padding-top: $image-height;

  &:after {
    content: '';
    background: $color-branding-background;
    position: absolute;
    top: $image-height;
    left: 0;
    right: 0;
    width: 100%;
    height: $card-height;
  }

  @include media-breakpoint-down(md) {
    padding-top: $image-height-mob;

    &:after {
      top: $image-height-mob;
      height: calc(#{$card-height-mob} * 2 + 1rem);
    }
  }
}

.b-header--no-title-circle {
  .b-header__text-circle-shape {
    &:before {
      z-index: 0;
    }
  }

  .b-header__title,
  .b-header__subtitle {
    text-shadow: 2px 2px rgba(0, 0, 0, .2);
  }
}

.b-header--skewed {
  $skewed-height: 4rem;

  &:before {
    content: '';
    background: $color-branding-background;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(#{$image-height} - #{$skewed-height});
    height: calc(#{$card-height} / 2);
    width: 100%;
    z-index: 2;
    transform: skew(3deg, -6deg) rotate(3deg);
  }

  @include media-breakpoint-down(md) {
    &:before {
      top: calc(#{$image-height-mob} - #{$skewed-height});
      height: calc(#{$card-height-mob} / 2 + 1rem);
      transform: skew(3deg, -7deg) rotate(3deg);
    }
  }
}

.b-header--smile {
  &:after {
    content: '';
    background: transparent;
    position: absolute;
    top: -17rem;
    left: 50%;
    width: 112%;
    height: 100%;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 11rem 0 5rem $color-branding-background;
    transform: translateX(-50%);
  }

  @include media-breakpoint-down(lg) {
    &:after {
      width: 144%;
    }
  }

  @include media-breakpoint-down(md) {
    &:after {
      width: 180%;
      top: -21rem;
    }
  }
}

.b-header--wave {
  &:after {
    background: transparent;
  }

  .b-header__wave-shape {
    display: block;
  }
}

.b-header__wave-shape {
  display: none;

  width: 120vw;
  height: 40rem;
  position: absolute;
  left: -34vw;

  @include media-breakpoint-down(lg) {
    left: -10vw;
    height: 30rem;
  }

  @include media-breakpoint-down(md) {
    width: 110vw;
    left: -10vw;
    right: 0;
    height: 14rem;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $color-branding-background;
    }
  }
}

.b-header__col {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
}

.b-header__image-container {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: $image-height;
  transform: translateX(-50%);


  @include media-breakpoint-down(md) {
    width: 100%;
    height: $image-height-mob;
  }
}

.b-header__header-image {
  @include absolute-centered-image(100%, $image-height, 100%, $image-height-mob);

  top: 0;
  transform: translateX(-50%);
}

.b-header__text-circle-shape {
  &:before {
    content: '';
    background: $color-branding-circle-background;
    border-radius: 50%;
    width: $image-height;
    height: $image-height;
    position: absolute;
    left: -6rem;
    top: 0;
    opacity: .9;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {

    &:before {
      width: $image-height-mob;
      height: $image-height-mob;
      transform: unset;
      left: -4rem;
      bottom: -4rem;
    }
  }
}

.b-header__image-text {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(#{$image-height} - 6rem);
  height: 100%;
  padding: 9rem 4rem 4rem 0;
  z-index: 2;

  @include media-breakpoint-down(md) {
    width: $image-height-mob;
    padding-left: 1rem;
    padding-top: 8rem;
  }

  @media (max-width: 376px) {
    width: 100%;
  }
}

.b-header__title {
  font-size: 3rem;
  line-height: 3rem;
  color: $color-white;
  margin-top: 0;
  margin-bottom: 2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
    line-height: 1.875rem;
    margin-bottom: 1.25rem;
  }
}

.b-header__subtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: $color-white;
  margin-bottom: 2.375rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 1.25rem;
  }
}

.b-header__cards {
  display: flex;
  transform: translateY(-4.125rem);
  position: relative;
  z-index: 2;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    border-radius: .125rem;
    overflow: hidden;
    box-shadow: $box-shadow;
    padding: 1rem;
    background: $color-white;
    transform: translateY(-2rem);

    &:after {
      content: '';
      background: $color-white;
      width: 1rem;
      height: 1rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.b-header__card {
  width: 100%;
  height: $card-height;
  background: $color-white;
  margin-right: .625rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 1.625rem 1.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $color-text;
  position: relative;

  &:before {
    content: '';
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background: $color-white;
    width: 100%;
    height: $card-height;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: transform $transition-default, opacity $transition-default 250ms;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $color-text;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      &:before {
        transition: transform $transition-default;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.05);
      }

      .b-header__card-wave-shape {
        transform: scaleX(-1) scale(1.05);
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-right: 0;
    height: $card-height-mob;
    width: 50%;
    border-radius: 0;
    box-shadow: none;

    &:nth-child(2) {
      border-left: 1px solid $color-border-light;
    }

    &:nth-child(3) {
      border-top: 1px solid $color-border-light;
    }

    &:nth-child(4) {
      border-left: 1px solid $color-border-light;
      border-top: 1px solid $color-border-light;
    }
  }
}

.b-header__card-text-container {
  z-index: 1;
}

.b-header__card-image {
  height: 2.5rem;
  width: auto;
  margin-bottom: .875rem;
  display: block;

  @include media-breakpoint-down(md) {
    height: 1.875rem;
    margin-bottom: .5rem;
  }
}

.b-header__card-title {
  line-height: 1.625rem;
  font-size: 1.25rem;
  font-weight: $font-weights-bold;
  margin-bottom: .5rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}

.b-header__card-text {
  color: $color-text-light;
  font-size: 1rem;
  line-height: 1.375rem;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.b-header__card-link {
  color: $color-branding-link-text;
  z-index: 1;
  font-size: 2rem;
  text-align: right;
}

.b-header__card-wave-shape {
  transform: scaleX(-1);
  position: absolute;
  top: 0;
  left: 0;
  opacity: .35;
  transition: transform $transition-default;

  @include media-breakpoint-down(md) {
    display: none;
  }

  svg {
    height: 12rem;
    width: 100%;
  }
}

.b-header__contact-panel-container {
  position: absolute;
  top: calc(#{$header-height} + 5rem);
  right: 0;
  z-index: 2;

  @include media-breakpoint-down(md) {
    top: calc(#{$header-height-mobile} + 2.5rem);
  }
}
