//@see ~bootstrap/scss/_variables.css

// Bootstrap grid
// Change this if you know what you're doing
// Bootstrap uses these for example for col-md-* sizing
$bp-xs: 0;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$gutter: 16px;

$grid-breakpoints: (
        xs: $bp-xs,
        sm: $bp-sm,
        md: $bp-md,
        lg: $bp-lg,
        xl: $bp-xl,
);

$container-max-widths: (
        sm: $bp-sm - 36px,
        md: $bp-md - 48px,
        lg: $bp-lg - 32px,
        xl: $bp-xl - 60px,
);

