/*
 * Base styling for header elements, default p and other base html elements
 */

body {
  font-size: $font-size-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-base;
  color: $color-text;

  @include media-breakpoint-down(md) {
    font-size: $font-size-body-mob;
  }
}

p {
  margin: .5rem 0;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

h1 {
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 3rem;
  font-weight: $font-weights-bold;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1.75rem;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  font-weight: $font-weights-bold;

  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: $font-weights-bold;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
