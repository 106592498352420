$text-shift-y-mob: 3.875rem;

.b-text-image {

}

.b-text-image__image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 36.125rem;
  border-radius: $border-radius;

  &:after {
    content: '';
    background: $color-branding-circle-background;
    border-radius: 50%;
    width: 45rem;
    height: 45rem;
    opacity: .4;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 39%);
  }

  @include media-breakpoint-down(md) {
    min-height: 24.375rem;

    &:after {
      width: 28.625rem;
      height: 28.625rem;
      top: 0;
      left: 0;
      transform: translate(-44%, -41%);
    }
  }
}

.b-text-image__image {
  @include absolute-centered-image(100%, 100%, 100%, 100%);
}

.b-text-image__text-wrapper {
  display: flex;
  align-items: center;
  width: calc(100% + 3.25rem);
  height: 100%;
  padding: 3rem 0;

  @include media-breakpoint-down(lg) {
    width: calc(100% + 4.25rem);
  }


  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 0 1.125rem;
    margin-top: -$text-shift-y-mob;
  }
}

.b-text-image__text {
  padding: 1.875rem 4.5rem 1.875rem 2.5rem;
  background: $color-branding-circle-background;
  color: $color-white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  transform: translateX(-9rem);

  @include media-breakpoint-down(md) {
    padding: 1rem .875rem;
    transform: translateY($text-shift-y-mob);
  }
}

.b-text-image__title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 0;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: .875rem;
  }
}

.b-text-image__text-content {
  color: $color-white;
  font-size: 1.125rem;
  line-height: 1.75rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}
