$button-width: 2.5rem;

.c-contact-panel {
   display: flex;
   transition: transform $transition-default;
   transform: translateX(calc(100% - #{$button-width}));

   &.is-open {
      transform: translateX(0);
   }
}

.c-contact-panel__buttons {

}

.c-contact-panel__button {
   transition: background-color $transition-default;
   background: $color-branding-button;
   color: $color-white;
   border-radius: $border-radius 0 0 $border-radius;
   padding: .5rem;
   display: block;
   width: $button-width;
   margin-bottom: .25rem;

   &:hover {
      background: $color-branding-button--hover;
   }
}

.c-contact-panel__button-icon {
   svg {
      max-height: 1.75rem;

      path {
         fill: $color-white;
      }
   }
}

.c-contact-panel__info {
   background: $color-white;
   border-bottom: .125rem solid $color-branding-button;
}

.c-contact-panel__title {
   background: $color-text;
   padding: .5rem 1rem;
   color: $color-white;
   font-weight: $font-weights-bold;
}

.c-contact-panel__info-data {
   font-size: 1rem;
   padding: .5rem 1rem;
}

.c-contact-panel__subtitle {
   color: $color-text;
   font-weight: $font-weights-bold;
   font-size: 1rem;
}

.c-contact-panel__link {
   color: $color-text-light;
   display: flex;
   align-items: start;
   font-size: .875rem;
   margin: .5rem 0;
}

.c-contact-panel__link-icon {
   margin-right: 1rem;
   width: .75rem;
   color: $color-text;
   display: block;
}
