$image-height: 13.25rem;
$image-height-mob: 9.125rem;

.b-recent-blogs {
  position: relative;
  overflow: hidden;
  padding-bottom: $block-margin;
  margin-bottom: 0;

  .tns-nav {
    display: none;
  }

  .tns-ovh {
    overflow: visible;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: $block-margin-mob;
  }
}

.b-recent-blogs__wave-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40rem;
  width: 105vw;
  z-index: -1;

  @include media-breakpoint-down(md) {
    height: 16rem;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $color-branding-background;
    }
  }
}

.b-recent-blogs__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 1.125rem;
  }
}

.b-recent-blogs__title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }
}

.b-recent-blogs__links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.b-recent-blogs__link {
  font-size: 1.125rem;
  font-weight: $font-weights-bold;
  line-height: 2.5rem;
  display: flex;
  margin-right: .5rem;

  &:hover {
    text-decoration: none;

    .b-recent-blogs__arrow-icon {
      transform: translateX(.5rem);

      svg {

        path {
          fill: $color-branding-link-text--hover;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.b-recent-blogs__arrow-icon {
  transition: transform $transition-default;
  margin-left: .5rem;
  display: flex;
  align-items: center;

  svg {
    width: 1rem;

    path {
      transition: $transition-default;
      fill: $color-branding-link-text;
    }
  }

  @include media-breakpoint-down(md) {
    svg {
      width: .75rem;
    }
  }
}

.b-recent-blogs__cards-container {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}

.b-recent-blogs__card-outer-container {
  padding-right: 2rem;
  width: calc(33.33333% + 2rem);
  outline: none;

  &:last-child {
    padding-right: 0;
    width: 33.33333%;
  }

  @include media-breakpoint-down(md) {
    padding-right: 0;
    width: 100%;
    padding-bottom: 2rem;

    &:last-child {
      padding-right: 0;
      width: 100%;
    }
  }
}

.b-recent-blogs__card {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
  background: $color-white;
  height: 100%;
  width: 100%;
}

.b-recent-blogs__card-image-container {
  position: relative;
  width: 100%;
  height: $image-height;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    height: $image-height-mob;
  }
}

.b-recent-blogs__card-image {
  @include absolute-centered-image(100%, 100%, 100%, 100%);
}

.b-recent-blogs__card-text-container {
  padding: .75rem 1.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$image-height});

  @include media-breakpoint-down(md) {
    padding: 1.25rem;
    height: calc(100% - #{$image-height-mob});
  }
}

.b-recent-blogs__card-date {
  font-size: .875rem;
  line-height: 2rem;
  color: $color-text-light;
}

.b-recent-blogs__card-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.b-recent-blogs__card-text {
  color: $color-text-light;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    font-size: .875rem;
    line-height: 1.375rem;
    margin-top: .75rem;
    margin-bottom: 1.25rem;
  }
}

.b-recent-blogs__card-link {
  margin-top: auto;
  margin-left: auto;
  font-size: 2rem;
  width: fit-content;

  &:hover {
    text-decoration: none;
  }
}
